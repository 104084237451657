import axios from 'axios'

let getRandomHexString = size => [...self.crypto.getRandomValues(new Uint8Array(size))].map(x => x.toString(16).padStart(2, '0')).join('')

const traceId = getRandomHexString(16)

var axiosApi = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: { 'Pragma': 'no-cache', 'Cache-Control': 'no-store', 'Accept-version': '2.1' } // eslint-disable-line
})

axiosApi.interceptors.response.use(
  response => response.data,
  error => Promise.reject(Object.assign({}, error))
)

axiosApi.interceptors.request.use(
  cfg => {
    const span = getRandomHexString(8)
    cfg.headers.traceparent = `00-${traceId}-${span}-00`
    return cfg
  }
)

export default axiosApi
