<template>
  <div class="user-settings">
    <div>
      <UserIcon />
    </div>
    <div class="name" v-if="profile">
      <span :title="isTruncated ? profile.fullName : null" class="full-name">{{this.truncatedName}}
      </span>
      <div>
        {{profile.isabelUserId}}
      </div>
    </div>
    <div>
      <LogoutButton
        data-testid="logout-button"
        :filled=true
        v-on:click="logout"
        >
        {{ $t('common.header.logout') }}<span class="material-icons">logout</span>
      </LogoutButton>
    </div>
  </div>
</template>

<style scoped>
  .user-settings {
    display: flex;
  }

  .name {
    margin: 0 1rem;
  }

  .material-icons {
    font-size: 1.2rem;
    margin-left: 12px;
    vertical-align: bottom;
  }

  .full-name {
    font-weight: bold;
    margin-bottom: 2px;
  }
</style>

<script>
import UserManager from '@/services/SecurityService'
import UserIcon from '@/components/UserIcon'
import axiosApi from "@/services/AxiosService";
import LogoutButton from "@/components/styled/LogoutButton";

export default {
  components: {
    LogoutButton,
    UserIcon
  },
  data () {
    return {
      userManager: new UserManager(),
      isabelUserId: null,
      profile: null,
      truncatedName: null,
      isTruncated: null,
    }
  },
  created () {
    this.userManager.getUserId().then((userId) => {
      this.isabelUserId = userId
      this.getProfile()
    })
  },
  methods: {
    getProfile () {
      axiosApi
          .get(`/users/${this.isabelUserId}`)
          .then((data) => {
            this.$emit('errorPath', null)
            this.profile = data.data
            this.truncatedName =  this.profile.fullName.length < 50
                                ? this.profile.fullName
                                : this.profile.fullName.substring(0, 50) + "...";
            this.isTruncated = this.truncatedName !== this.profile.fullName;
            this.profile.isabelUserId = `${this.profile.isabelUserId.substring(0, 1)}-${this.profile.isabelUserId.substring(1, 8)}-${this.profile.isabelUserId.substring(8, 11)}-${this.profile.isabelUserId.substring(11)}`
          })
          .catch(() => {
            this.$emit('errorPath', 'profile.errors.getProfile')
          })
    },
    logout () {
      this.userManager.signOut()
    }
  }
}
</script>
